import React from "react";
import Banner from "../components/CommonBanner";
import HeaderMenuBS from "../components/HeaderMenuBS"
import Portfolio from "../containers/PortfolioPage1/Portfolio";
import Footer from "../components/Footer";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundImg from '../assets/portfolio-1-images/banner.jpg';

const PortfolioPage1 = () => (
    <Layout>
        <SEO 
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS />
        <Banner 
            BackgroundImg={BackgroundImg}
            pageTitle="Our Portfolio"
            breadCrumbsFirst="Home"
            breadCrumbsSecond="Our Portfolio"
            breadCrumbsFirstLink="/"
            breadCrumbsSecondLink="/portfolio-1"
        />
        <Portfolio />
        <Footer />
    </Layout>
)

export default PortfolioPage1;