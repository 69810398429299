import React from 'react';
import {PortfolioSection,PortfolioHeading,CustomTabList,ImageHolder,ContentHolder} from './portfolio.style';
import {Row,Col,Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab, Tabs, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const PortfolioPage = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                portfolioPage1Json{
                    Portfolio{
                        PortfolioHeading
                        TabList{
                            Tab
                        }
                        TabContent{
                            Tab{
                                Row{
                                    Img{
                                        childImageSharp{
                                            fluid(quality: 100){
                                            ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                    Text1
                                    Text2
                                }
                            }
                        }
                    }
                }
            }
    `);
    const PortfolioData = JSONData.portfolioPage1Json.Portfolio;
        return (

                <PortfolioSection id="PortfolioContainer">
                    <Container>
                        <PortfolioHeading>{PortfolioData.PortfolioHeading}</PortfolioHeading>
                        <Tabs>
                            <CustomTabList>
                            {
                                PortfolioData.TabList.map((item,idx) => {
                                    return <Tab>{item.Tab}</Tab>
                                })
                            }
                            </CustomTabList>
                            {
                                PortfolioData.TabContent.map((itemTab) => {
                                return <TabPanel>
                                        <Row>
                                        {
                                            itemTab.Tab.Row.map((item) => {
                                            return <Col lg={4} md={6}>
                                                <ImageHolder>
                                                    <GatsImg
                                                        fluid={item.Img.childImageSharp.fluid}
                                                        alt=""
                                                        className="PortfolioImage"
                                                    />
                                                    <ContentHolder>
                                                        <h4>{item.Text1}</h4>
                                                        <p>{item.Text2}</p>
                                                    </ContentHolder>
                                                </ImageHolder>
                                            </Col>
                                            })
                                        }
                                        </Row>
                                    </TabPanel>
                                })
                            }
                        </Tabs>
                    </Container>
                </PortfolioSection>
            );
        }

        export default PortfolioPage;
