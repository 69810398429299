import styled from 'styled-components';
import {device} from '../Common/device';

export const BannerSection = styled.section`
    background-image:url(${props => props.BackgroundImg});
    padding:70px 0px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height:380px;
    display:flex;
    align-items:center;

    @media ${device.tablet} {
        background-size:cover;
        padding:80px 0px;
    }
`;

export const BannerContents = styled.div`
    padding-top:50px;

    @media ${device.tablet} {
        display:block;
        padding-top:40px;
    }

`;

export const BannerContentLeft = styled.div`
    max-width: 700px;
    padding-right: 25px;
    margin:auto;

    h3{
        font-size:50px;
        font-weight:700;
        margin:0px 0px 20px;
        line-height: 50px;
        color:#fff;
        text-align:center;

        @media ${device.tablet} {
            width:100%;
            font-size: 40px;
            line-height:44px;
        }

        @media ${device.mobileXL} {
            width:100%;
            font-size: 36px;
            line-height:40px;
        }
    }

    p{
        color:#fff;
        text-align:center;
    }
    @media ${device.laptop} {
        width:100%;
        padding: 10px;
    }

    a{
        font-size:16px;
        color:#fff;

        :hover{
            color:#fff;
        }
    }
`;

export const BreadCrumbsLayout = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
`;



export const Divider = styled.div`
    height:20px;
    width:1px;
    background:#fff;
    margin:0px 10px;
    transform: rotate(20deg);
`;
