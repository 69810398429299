import styled from 'styled-components';
import { TabList} from 'react-tabs';
import {device} from '../../../components/Common/device';

export const PortfolioSection = styled.section`
    padding:100px 0px 70px;
    border-bottom:1px solid #ccc;
    @media ${device.tablet} {
        padding:80px 5px 50px;
    }
`;

export const PortfolioHeading = styled.h2`
    margin-bottom: 75px;
    text-align: center;
    margin-left:0px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const CustomTabList = styled(TabList)`
    margin-bottom: 30px;
    text-align: center;
    margin-left:0px;

    .react-tabs__tab{
        border:none;
        background:#f7f7f7;
        margin:0px 10px;
        padding:8px 15px;
        font-size:16px;
        margin-bottom:10px;

        @media ${device.tablet} {
            margin-bottom:20px;
        }
        @media ${device.mobileL} {
            margin-bottom:20px;
            display:block;
        }
    }

    .react-tabs__tab--selected{
        background:#ff3c1c;
        border:none;
        border-radius:4px;
        color:#fff;
    }
`;
export const ImageHolder = styled.div`
    margin-bottom:30px;
    cursor:pointer;
    overflow: hidden;
    border-radius: 4px;
    position: relative;

    .PortfolioImage{
        height:260px;
        width:100%;
    }
`;


export const ContentHolder = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left:0;
    right:0;
    background: rgba(0,0,0,.5);
    padding: 20px;
    border-radius: 4px;

    h4{
        color:#fff;
    }

    p{
        color:#fff;
    }
`;
