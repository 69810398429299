import React from 'react';
import {BannerSection,
    BannerContents,
    BannerContentLeft,
    BreadCrumbsLayout,
    Divider
} from './banner.style'
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Banner = (props) => {
    return (
        <BannerSection id="homeContainer" BackgroundImg={props.BackgroundImg} >
            <Container>
                <BannerContents>
                    <BannerContentLeft>
                        <h3>{props.pageTitle}</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                        <BreadCrumbsLayout>
                            <a href={props.breadCrumbsFirstLink}>{props.breadCrumbsFirst}</a>
                            <Divider />
                            <a href={props.breadCrumbsSecondLink}>{props.breadCrumbsSecond}</a>
                        </BreadCrumbsLayout>
                    </BannerContentLeft>
                </BannerContents>
            </Container>
        </BannerSection>
    )
}

export default Banner;
